import React from 'react';
import Select from 'react-select';

const SelectCompnent = ({ className, options, title }) => {
	return (
		<div className='searchbar-select-wrapper position-relative'>
			<p className='mb-0 searchbar-select__title position-absolute'>
				{title}
			</p>
			<Select
				classNamePrefix='searchbar-select__main'
				className={className}
				options={options}
			/>
		</div>
	);
};

export default SelectCompnent;

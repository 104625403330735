import React from 'react';
import RatedCardListing from '../../components/RatedNFT/RatedCardListing';
import SearchBar from '../../components/shared/Common/SearchBar';
import WrapperDiv from '../../components/shared/Common/WrapperDiv';

const RatedNFT = () => {
	return (
		<>
			<SearchBar ratedNFTPage='true' />
			<WrapperDiv title='Rated NFTs'>
				<RatedCardListing />
			</WrapperDiv>
		</>
	);
};

export default RatedNFT;

import React from 'react';
import RatedCard from '../shared/Common/RatedCard';

const RatedCardListing = () => {
	return (
		<div className='rated__card-wrapper'>
			<RatedCard
				img='/images/sample1.jpg'
				title='United Punks Union'
				titleHash=' # 1883990'
			/>
			<RatedCard
				img='/images/sample2.jpg'
				title='United Punks Union'
				titleHash=' # 1883992'
			/>
			<RatedCard
				img='/images/sample3.jpg'
				title='United Punks Union'
				titleHash=' # 1883991'
			/>
			<RatedCard
				img='/images/sample4.jpg'
				title='United Punks Union'
				titleHash=' # 1883993'
			/>
		</div>
	);
};

export default RatedCardListing;

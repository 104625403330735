import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RatedCard = ({ img, title, titleHash }) => {
	return (
		<Link to='/rated-nft/1' className='common-link'>
			<Card className='common__card rated__card'>
				<div className='d-flex'>
					<img className=' rated__card-img' src={img} alt='' />
					<div className='rated__card-rightside'>
						<h5>{title}</h5>
						<h6>
							<span>Genie Trend:</span>
							{titleHash}
						</h6>
						<hr className='rated__card-hr' />
						<div className='text__wrapper'>
							<div className='text__left'>
								<p>Owners</p>
								<span className='blue__line blue__line1'></span>
							</div>
							<div className='text__right'>
								<p>1</p>
							</div>
						</div>
						<div className='text__wrapper'>
							<div className='text__left'>
								<p>Total Volume</p>
								<span className='blue__line blue__line2'></span>
							</div>
							<div className='text__right'>
								<p>61</p>
							</div>
						</div>
						<div className='text__wrapper'>
							<div className='text__left'>
								<p>Category</p>
								<span className='blue__line blue__line3'></span>
							</div>
							<div className='text__right'>
								<p>Metaverse Land</p>
							</div>
						</div>
						{/* <div className='text__wrapper'>
						<div className='text__left'>
							<p>Category</p>
							<span className='blue__line blue__line4'></span>
						</div>
						<div className='text__right'>
							<p>Metaverse Land</p>
						</div>
					</div> */}
					</div>
				</div>
				<p className='rated__card-desc mt-2'>
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Eligendi, reprehenderit ex est optio voluptatem architecto
					vero dolorem? Assumenda, quae similique ab corrupti ratione
					voluptas officia quidem cum et ad dolore at? Vel iste
					dolorum consequatur, dicta laudantium ipsa deleniti fugit
					vitae error consectetur temporibus, culpa placeat animi
					quibusdam. Accusamus, unde?
				</p>
			</Card>
		</Link>
	);
};

export default RatedCard;

import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const BreadCrumb = () => {
	return (
		<div className='breadcrumb-wrapper'>
			<Breadcrumb>
				<Breadcrumb.Item href='/'>&#60; Back</Breadcrumb.Item>
				<hr className='breadcrumb-hr' />
				<Breadcrumb.Item href='/'>Rated NFTs</Breadcrumb.Item>
				<Breadcrumb.Item active>United Punks Union</Breadcrumb.Item>
			</Breadcrumb>
		</div>
	);
};

export default BreadCrumb;

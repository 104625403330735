import React from 'react';
import { Card } from 'react-bootstrap';
import SocialLinks from './SocialLinks';

const EventsCard = ({ description }) => {
	return (
		<Card className='common__card events__card'>
			<div className='events__card-title'>United Punks Union</div>
			<div className='img__wrapper'>
				<img src='/images/sample1.jpg' alt='' />
				<img src='/images/sample2.jpg' alt='' />
				<img src='/images/sample3.jpg' alt='' />
				<img src='/images/sample4.jpg' alt='' />
			</div>
			<div className='row__wrapper'>
				<div className='events__text-wrapper'>
					<h6>Price</h6>
					<p>0.05ETH</p>
				</div>
				<div className='events__text-wrapper'>
					<h6>Supply</h6>
					<p>10,000</p>
				</div>
				<div className='events__text-wrapper'>
					<h6>Sale date</h6>
					<p>5th Aug 2021</p>
				</div>
				<div className='events__text-wrapper'>
					<h6>Time</h6>
					<p>4:30 am (Asia/Calcutta)</p>
				</div>
			</div>
			<hr className='events__hr' />
			<div className='desc__wrapper'>
				<h6>Description</h6>
				<p>{description}</p>
			</div>
			<SocialLinks />
		</Card>
	);
};

export default EventsCard;

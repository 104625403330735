import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
	labels: [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
	],
	datasets: [
		{
			label: 'Today Price',
			data: [
				0, 1900, 3000, 5000, 2000, 3000, 1221, 1933, 3445, 5424, 2245,
				3123, 7222, 1000,
			],
			fill: false,
			backgroundColor: '#2081E2',
			borderColor: '#2081E2',
			borderWidth: 1,
		},
		{
			label: 'Yesterday Price',
			data: [
				0, 1900, 3000, 5000, 2000, 3000, 1221, 1933, 3445, 5424, 2245,
				3123, 7222, 1000,
			],
			fill: false,
			backgroundColor: '#797979',
			borderColor: '#797979',
			borderWidth: 1,
		},
		{
			label: 'Week Price',
			data: [
				0, 1273, 900, 3054, 3298, 3023, 3362, 8000, 1933, 3054, 3298,
				1890, 3520, 1200,
			],
			fill: false,
			backgroundColor: '#EDEDED',
			borderColor: '#EDEDED',
			borderWidth: 1,
		},
		{
			label: 'Month Price',
			data: [
				0, 900, 3054, 3298, 1890, 3520, 1200, 1273, 3475, 7424, 8245,
				3023, 3222, 8000,
			],
			fill: false,
			backgroundColor: '#C1C1C1',
			borderColor: '#C1C1C1',
			borderWidth: 1,
		},
	],
};

const ChartComp = () => {
	return (
		<div className='mt-5'>
			<Line
				width={600}
				height={400}
				data={data}
				options={{
					maintainAspectRatio: false,
				}}
			/>
		</div>
	);
};

export default ChartComp;

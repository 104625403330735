import React from 'react';
import BreadCrumb from '../shared/Common/BreadCrumb';
import WrapperDiv from '../shared/Common/WrapperDiv';
import SocialLinks from '../shared/Common/SocialLinks';
import CollectionsCard from '../shared/Common/CollectionsCard';
import GraphCard from '../shared/Common/GraphCard';
import CollectionTitleBar from './CollectionTitleBar';

const RatedCardDetailsScreen = () => {
	return (
		<>
			<BreadCrumb />
			<WrapperDiv title='Rated NFTs'>
				<div className='graph-wrapper'>
					<div className='graph__title-row d-flex align-items-center justify-content-between'>
						<h2>United Punks Union</h2>
						<SocialLinks />
					</div>
					<GraphCard />
				</div>
				<div className='graph__about-wrapper d-flex justify-content-between'>
					<div className='graph__about-leftside'>
						<h3>About Collection</h3>
						<div>
							<p>
								Lorem ipsum dolor sit amet, consetetur
								sadipscing elitr, sed diam nonumy eirmod tempor
								invidunt ut labore et dolore magna juq Lorem
								ipsum dolor sit amet, consetetur sadipscing
								elitr, sed diam nonumy eirmod tempor invidunt ut
								labore consetetur sadipscing elitr, sed di Lorem
								ipsum dolor sit amet, consetetur sadipscing
								elitr, sed diam nonumy eirmod tempor invidunt ut
								labore et dolore magna juq Lorem ipsum dolor sit
								amet, consetetur sadipscing elitr, sed diam
								nonumy eirmod tempor invidunt ut labore
								consetetur sadipscing elitr.
							</p>
						</div>
					</div>
					<div className='graph__about-rightside'>
						<h3>About Artist</h3>
						<p>
							Lorem ipsum dolor sit amet, consetetur sadipscing
							elitr, sed diam nonumy eirmod tempor invidunt ut
							labore et dolore magna juq Lorem ipsum dolor sit
							amet, consetetur sadipscing elitr, sed diam nonumy
							eirmod tempor invidunt ut lab
						</p>
					</div>
				</div>
				<div className='collection-wrapper'>
					<div className='main__margin'>
						<CollectionTitleBar />
					</div>
					<div className='collection-cards__wrapper mt-4 d-flex flex-wrap align-items-center'>
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
					</div>
				</div>
			</WrapperDiv>
		</>
	);
};

export default RatedCardDetailsScreen;

import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TreadingCard = ({ img }) => {
	return (
		<Link className='common-link flex-grow-1' to='/rated-nft/1'>
			<Card className='common__card treading__card'>
				<img className=' treading__card-img' src={img} alt='' />
				<div className='treading__card-middle'>
					<h5>United Punks Union</h5>
					<p>
						Added:<span> 5th Aug 2021</span>
					</p>
					<p>
						Owners Percentage:<span> 52.76% </span>
					</p>
				</div>
				<div className='treading__card-last d-flex justify-content-between align-items-center flex-grow-1'>
					<div className='treading__text-wrapper'>
						<p>8,425.30 ETH</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>8,425.30 ETH</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>8828</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>19.10 ETH</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>19,330</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>441</p>
					</div>
					<div className='treading__text-wrapper'>
						<p>191,049.90 ETH</p>
					</div>
				</div>
			</Card>
		</Link>
	);
};

export default TreadingCard;

import React from 'react';
import { Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Input from '../../components/shared/Common/Input';
import WrapperDiv from '../../components/shared/Common/WrapperDiv';

const ListYourProject = () => {
	return (
		<WrapperDiv title='List Your Project'>
			<div className='list__project-wrapper mx-auto'>
				<Accordion defaultActiveKey='0'>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>
							How to list your NFTs?
						</Accordion.Header>
						<Accordion.Body>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud exercitation ullamco laboris nisi ut aliquip
							ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore
							eu fugiat nulla pariatur. Excepteur sint occaecat
							cupidatat non proident, sunt in culpa qui officia
							deserunt mollit anim id est laborum.
						</Accordion.Body>
					</Accordion.Item>
					<hr className='faq__hr' />
					<Accordion.Item eventKey='1'>
						<Accordion.Header>
							What are the Benefits of holding NFTs
						</Accordion.Header>
						<Accordion.Body>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud exercitation ullamco laboris nisi ut aliquip
							ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore
							eu fugiat nulla pariatur. Excepteur sint occaecat
							cupidatat non proident, sunt in culpa qui officia
							deserunt mollit anim id est laborum.
						</Accordion.Body>
					</Accordion.Item>
					<hr className='faq__hr' />
				</Accordion>
				<div className=''>
					<Form>
						<Input
							className='mt-5'
							label='Email*'
							type='email'
							placeholder='your email address'
						/>
						<Input
							className='mt-5'
							label="Your Project's Name*"
							type='text'
							placeholder='your answer'
						/>
						<Input
							className='mt-5'
							label='Short Description'
							type='text'
							placeholder='your answer'
						/>
						<Input
							className='mt-5'
							label='OpenSea Link *'
							type='text'
							placeholder='your answer'
							desc={`Enter as <a href='/'> https://opensea.io/collection/yourcollectionname </a>, If you don't have one maybe wait until you do before submitting this form (we can't list you yet anyways). Or if you do want to submit it early, whenever you get your collection link, please DM us on twitter to let us know. Otherwise it is hard for us to keep track of your project's progress.`}
						/>
						<Input
							className='mt-5'
							label='Website URL'
							type='text'
							placeholder='your answer'
						/>
						<Input
							className='mt-5'
							label="Your Project's Official Twitter Link*"
							type='text'
							placeholder='your answer'
							desc={`Enter as https://twitter.com/yourtwitterhandle, if you don't have an official twitter for the project provide your personal twitter`}
						/>
						<Input
							className='mt-5'
							label="Your Project's Official Discord Link"
							type='text'
							placeholder='your answer'
							desc={`Enter as https://discord.com/yourdiscordhandle, if you don't have an official twitter for the project provide your personal twitter`}
						/>
						<Input
							className='mt-5'
							label="Your Project's Official Instagram Link"
							type='text'
							placeholder='your answer'
							desc={`Enter as https://instagram.com/yourinstagramhandle, if you don't have an official twitter for the project provide your personal twitter`}
						/>
						<Input
							className='mt-5'
							label="Your Project's Official Facebook Link"
							type='text'
							placeholder='your answer'
							desc={`Enter as https://facebook.com/yourfacebookhandle, if you don't have an official twitter for the project provide your personal twitter`}
						/>
						<div className='mt-5'>
							<div className='input__label'>Project Status</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault1'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault1'>
									I have minted my NFTs on opensea
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault2'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault2'>
									Am posting for future event
								</label>
							</div>
							<div className='d-flex align-items-center ms-5'>
								<div className='form-check me-5'>
									<input
										className='form-check-input'
										type='radio'
										name='flexDefault'
										id='flexRadioDefault1'
									/>
									<label
										className='form-check-label'
										for='flexDefault1'>
										Sale date for upcoming event
									</label>
								</div>
								<div className='form-check'>
									<input
										className='form-check-input'
										type='radio'
										name='flexDefault'
										id='flexRadioDefault2'
									/>
									<label
										className='form-check-label'
										for='flexDefault2'>
										Sale date unknown
									</label>
								</div>
							</div>
							<div className='date__input-wrapper'>
								<Input
									className='date__input'
									type='Date'
									placeholder='MM/DD/YYYY'
								/>
							</div>
						</div>
						<div className='mt-5'>
							<div className='input__label'>
								Your Project's Sale Start Date (If available)
								(in UTC only*)
							</div>
							<p className='desc__form-text'>
								Specify future date if not launched yet, past
								date if sale already started. Can leave blank if
								not determined yet or so long ago it doesn't
								matter.
							</p>
							<div className='d-flex align-items-center'>
								<Input
									className='date__input'
									type='Date'
									label='Date'
									placeholder='MM/DD/YYYY'
								/>
								<Input
									className='time__input ml_60'
									type='Time'
									label='Time'
									placeholder='__:__'
								/>
							</div>
						</div>
						<div className='mt-5'>
							<div className='input__label'>
								Your Project's Sale End Date (If available)
							</div>
							<p className='desc__form-text'>
								Specify future date if not ended yet, past date
								if sale already ended. Can leave blank if not
								determined yet or so long ago it doesn't matter.
							</p>
							<div className='d-flex align-items-center'>
								<Input
									className='date__input'
									type='Date'
									label='Date'
									placeholder='MM/DD/YYYY'
								/>
								<Input
									className='time__input ml_60'
									type='Time'
									label='Time'
									placeholder='__:__'
								/>
							</div>
						</div>
						<div className='mt-5'>
							<div className='input__label'>
								Your Project's Posting Date (If available)
							</div>
							<p className='desc__form-text'>
								Specify future date if not ended yet, past date
								if sale already ended. Can leave blank if not
								determined yet or so long ago it doesn't matter.
							</p>
							<div className='d-flex align-items-center'>
								<Input
									className='date__input'
									type='Date'
									label='Date'
									placeholder='MM/DD/YYYY'
								/>
								<Input
									className='time__input ml_60'
									type='Time'
									label='Time'
									placeholder='__:__'
								/>
							</div>
						</div>
						<Input
							className='mt-5'
							label='Total Collection Number'
							type='text'
							placeholder='your answer'
						/>
						<div className='mt-5'>
							<div className='input__label'>
								What is your collection's blockchain? *
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault1'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault1'>
									Ethereum
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault2'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault2'>
									Matic
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault3'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault3'>
									Binance Smart Chain
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault4'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault4'>
									Cardano
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault5'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault5'>
									Wax
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault6'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault6'>
									Solana
								</label>
							</div>
							<div className='form-check'>
								<input
									className='form-check-input'
									type='radio'
									name='flexRadioDefault'
									id='flexRadioDefault7'
								/>
								<label
									className='form-check-label'
									for='flexRadioDefault7'>
									Other
								</label>
							</div>
						</div>
						<Input
							className='mt-5'
							label="Please provide links to some example images from your collection ( max at 4 ) (Only image, Don't accept .gif)"
							type='text'
							placeholder='your answer'
						/>
						<Input
							className='mt-5'
							label='If Your Project Is Upcoming, What Is Your Unit Price (ETH)'
							type='text'
							placeholder='your answer'
						/>
						<Input
							className='mt-5'
							label='Anything You Would Like To Add'
							type='text'
							placeholder='your answer'
						/>
						<hr className='my-5 mx-auto list__form-hr' />
						<div className='d-flex justify-content-center'>
							<button className='common-btn make__payment-btn'>
								Make Payment
							</button>
						</div>
					</Form>
				</div>
			</div>
		</WrapperDiv>
	);
};

export default ListYourProject;

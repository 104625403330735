import 'bootstrap/dist/css/bootstrap.min.css';
import './App.sass';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavbarTop from './components/shared/Navbar/NavbarTop';
import Footer from './components/shared/Footer/Footer';
import RatedNFT from './pages/RatedNFT/RatedNFT';
import Treading from './pages/Treading/Treading';
import UpcomingEvents from './pages/UpcomingEvents/UpcomingEvents';
import About from './pages/About/About';
import Faqs from './pages/FAQ/Faqs';
import ListYourProject from './pages/ListYourProject/ListYourProject';
import RatedCardDetailsScreen from './components/RatedNFT/RatedCardDetailsScreen';
import CollectionCardsListing from './components/RatedNFT/CollectionCardsListing';

function App() {
	return (
		<Router>
			<NavbarTop />
			<Switch>
				<Route path='/list-your-project' component={ListYourProject} />
				<Route path='/faqs' component={Faqs} />
				<Route path='/about' component={About} />
				<Route path='/upcoming-events' component={UpcomingEvents} />
				<Route path='/treading' component={Treading} />
				<Route
					path='/rated-nft/collection'
					component={CollectionCardsListing}
				/>
				<Route path='/rated-nft/1' component={RatedCardDetailsScreen} />
				<Route path='/rated-nft' component={RatedNFT} />
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;

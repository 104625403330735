import React from 'react';
import SearchBar from '../../components/shared/Common/SearchBar';
import TreadingCard from '../../components/shared/Common/TreadingCard';
import WrapperDiv from '../../components/shared/Common/WrapperDiv';

const Treading = () => {
	return (
		<>
			<SearchBar treadingPage='true' />
			<WrapperDiv title='Treading'>
				<div className='treading__card-wrapper'>
					<TreadingCard img='/images/sample1.jpg' />
					<TreadingCard img='/images/sample2.jpg' />
					<TreadingCard img='/images/sample3.jpg' />
					<TreadingCard img='/images/sample4.jpg' />
				</div>
			</WrapperDiv>
		</>
	);
};

export default Treading;

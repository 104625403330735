import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NavbarTop = () => {
	return (
		<header>
			<Navbar bg='light' expand='lg'>
				<Container fluid className='main__padding '>
					<Navbar.Brand
						href='/'
						className='d-flex align-items-center justify-content-start'>
						<img src='/images/logo.png' alt='logo' />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/rated-nft'>
								Rated NFTs
							</NavLink>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/treading'>
								Trending
							</NavLink>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/upcoming-events'>
								Upcoming Events
							</NavLink>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/about'>
								About
							</NavLink>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/faqs'>
								FAQ's
							</NavLink>
							<NavLink
								className='nav-link '
								activeClassName='nav-link__active'
								to='/list-your-project'>
								List your project
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default NavbarTop;

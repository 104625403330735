import React from 'react';

const Footer = () => {
	return (
		<footer className='d-flex align-items-center justify-content-center'>
			<img src='/images/logo.png' alt='Logo' />
		</footer>
	);
};

export default Footer;

import React from 'react';
import { Card } from 'react-bootstrap';
import ChartComp from './ChartComp';

const GraphCard = () => {
	return (
		<Card className='common__card graph-card'>
			<div className='graph-card__top d-flex'>
				<img
					className='graph-card__img'
					src='/images/sample2.jpg'
					alt=''
				/>
				<div className='d-flex flex-column flex-grow-1 justify-content-between'>
					<div className='graph-card__details-wrapper d-flex justify-content-between'>
						<div className='graph-card__details'>
							<p className='bold-text-gray'>Total Volume</p>
							<p className='bold-text-blue'>7,425.30 ETH</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Genie Trend</p>
							<p className='text-gray'>#782992</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>7 Day Volume</p>
							<p className='text-gray'>8,425.30 ETH</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Avg Price</p>
							<p className='text-gray'>19.10 ETH</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Est Market Cap</p>
							<p className='text-gray'>191,049.90 ETH</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Total Sale</p>
							<p className='text-gray'>19,330</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>7 Day Sale</p>
							<p className='text-gray'>441</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Floor Price</p>
							<p className='text-gray'>9.98 ETH</p>
						</div>
						<div className='graph-card__details'>
							<p className='italic-text-blue'>Owners %</p>
							<p className='text-gray'>52.76%</p>
						</div>
					</div>
					<div className='d-flex align-items-center'>
						<svg
							width='21px'
							xmlns='http://www.w3.org/2000/svg'
							aria-hidden='true'
							className='svg-inline--fa fa-caret-down fa-w-10'
							data-icon='caret-down'
							data-prefix='fas'
							viewBox='0 0 320 512'>
							<path
								fill='#FF0000'
								d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'></path>
						</svg>
						{/* <svg
							width='21px'
							xmlns='http://www.w3.org/2000/svg'
							aria-hidden='true'
							className='svg-inline--fa fa-caret-up fa-w-10'
							data-icon='caret-up'
							data-prefix='fas'
							viewBox='0 0 320 512'>
							<path
								fill='currentColor'
								d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'></path>
						</svg> */}
						<span className='graph-card__percentage'>28%</span>
					</div>
				</div>
			</div>
			<div className='graph-card__bottom'>
				<ChartComp />
			</div>
		</Card>
	);
};

export default GraphCard;

import React from 'react';
import WrapperDiv from '../../components/shared/Common/WrapperDiv';

const About = () => {
	return (
		<WrapperDiv title='About'>
			<div className='about-wrapper mx-auto'>
				<div className='blog__wrapper'>
					<h6>Heading One</h6>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr, sed di Lorem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore
						magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr.
					</p>
				</div>
				<hr className='about__hr' />
				<div className='blog__wrapper'>
					<h6>Heading Two</h6>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr, sed di Lorem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore
						magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna aliquyam erat, sed diam voluptua. At vero
						eos et accusam et justo duo dolores et ea rebum. Stet
						clita kasd gubergren, no sea takimata sanctus est Lorem
						ipsum dolor sit amet. Lorem ipsum dolor sit amet,
						consetetur sadipscing elitr, sed diam nonumy eirmod
						tempor invidunt ut labore et dolore magna aliquyam erat,
						sed diam voluptua. At vero eos et accusam et justo duo
						dolores et ea rebum. Stet clita kasd gubergren, no sea
						takimata sanctus est Lorem ipsum dolor sit amet.
					</p>
				</div>
				<hr className='about__hr' />
				<div className='blog__wrapper'>
					<h6>Heading Three</h6>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
						sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr, sed di Lorem
						ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore
						magna juq Lorem ipsum dolor sit amet, consetetur
						sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore consetetur sadipscing elitr.
					</p>
				</div>
			</div>
		</WrapperDiv>
	);
};

export default About;

import React from 'react';
import Range from '../shared/Common/Range';

const CollectionTitleBar = () => {
	return (
		<div className='collection-titlebar__wrapper main__padding'>
			<ul className='p-0 m-0 d-flex align-items-center'>
				<li>
					<p>NFTs</p>
				</li>
				<hr className='titlebar-hr' />
				<li>
					<div className='d-flex justify-content-center align-items-center'>
						<button className='common-btn titlebar-btn'>
							Buy Now
						</button>
						<button className='common-btn titlebar-btn'>New</button>
						<button className='common-btn titlebar-btn'>
							On Auction
						</button>
					</div>
				</li>
				<hr className='titlebar-hr' />
				<li>
					<div className='range__wrapper'>
						<span className='range__text-span me-3'>Price: $0</span>
						<Range />
						<span className='range__text-span ms-3'>$0</span>
					</div>
				</li>
				<hr className='titlebar-hr' />
				<li>
					<div className='range__wrapper'>
						<span className='range__text-span me-3'>
							Rarity: #1
						</span>
						<Range />
						<span className='range__text-span ms-3'>#1</span>
					</div>
				</li>
				<hr className='titlebar-hr' />
				<li>
					<p className='mb-0 reset__link'>Reset</p>
				</li>
			</ul>
		</div>
	);
};

export default CollectionTitleBar;

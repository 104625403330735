import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 162,
	},
	margin: {
		height: 47.6,
	},
}));

function ValueLabelComponent(props) {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
			{children}
		</Tooltip>
	);
}

ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
};

function Range() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Slider
				ValueLabelComponent={ValueLabelComponent}
				aria-label='custom thumb label'
				defaultValue={0}
			/>
		</div>
	);
}

export default Range;

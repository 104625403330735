import React from 'react';

const WrapperDiv = ({ title, children }) => {
	return (
		<div className='wrapper__div main__padding'>
			<div className='wrapper__div-title'>{title}</div>
			<div className='wrapper__div-children'>{children}</div>
		</div>
	);
};

export default WrapperDiv;

import React from 'react';
import { Link } from 'react-router-dom';

const SocialLinks = () => {
	return (
		<div className='social__links-wrapper'>
			<div className='social'>
				<span>
					<img src='/images/feather-link.svg' alt='' />
				</span>
				<Link to='/'>Website</Link>
			</div>
			<div className='social'>
				<span>
					<img src='/images/awesome-twitter.svg' alt='' />
				</span>
				<Link to='/'>Twitter</Link>
			</div>
			<div className='social'>
				<span>
					<img src='/images/awesome-medium.svg' alt='' />
				</span>
				<Link to='/'>Medium</Link>
			</div>
			<div className='social'>
				<span>
					<img src='/images/awesome-discord.svg' alt='' />
				</span>
				<Link to='/'>Discord</Link>
			</div>
			<div className='social'>
				<span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						ariaHidden='true'
						className='svg-inline--fa fa-telegram-plane fa-w-14'
						data-icon='telegram-plane'
						data-prefix='fab'
						viewBox='0 0 448 512'
						style={{ width: 15.53 }}>
						<path
							fill='#039BE5'
							d='M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z'></path>
					</svg>
				</span>
				<Link to='/'>Telegram</Link>
			</div>
			<div className='social'>
				<span>
					<img src='/images/instagram.png' alt='' />
				</span>
				<Link to='/'>Instragram</Link>
			</div>
			<div className='social'>
				<span>
					<img src='/images/awesome-facebook-square.svg' alt='' />
				</span>
				<Link to='/'>Fb</Link>
			</div>
		</div>
	);
};

export default SocialLinks;

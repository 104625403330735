import React from 'react';
import { Form } from 'react-bootstrap';
import Input from './Input';
import SelectCompnent from './SelectCompnent';

const SearchBar = ({ treadingPage, ratedNFTPage }) => {
	const categoriesOptions = [
		{ value: 'showAll', label: 'Show All' },
		{ value: 'avatars', label: 'Avatars' },
		{ value: 'masterpiece', label: 'Masterpiece' },
		{ value: 'gaming', label: 'Gaming' },
		{ value: 'metaverseLand', label: 'Metaverse Land' },
	];
	const sortByOptions = [
		{ value: 'launchDate', label: 'Launch Date' },
		{ value: 'genieTrend', label: 'Genie Trend' },
		{ value: 'noOfOwners', label: 'No. Of Owners' },
	];

	return (
		<div className='searchbar-wrapper main__padding d-flex align-items-center justify-content-between'>
			<Input
				className='searchIcon__input'
				type='text'
				placeholder='Search'
				icon='true'
			/>
			{ratedNFTPage && (
				<div className='searchbar-rightside d-flex align-items-center justify-content-end '>
					<SelectCompnent
						title='Categories:'
						className='searchbar-select me-4'
						options={categoriesOptions}
					/>
					<SelectCompnent
						title='Sort By:'
						className='searchbar-select'
						options={sortByOptions}
					/>
				</div>
			)}
			{treadingPage && (
				<div className='searchbar-rightside d-flex align-items-center justify-content-between '>
					<Form.Select>
						<option>Total Volume</option>
						<option>Default select</option>
					</Form.Select>
					<Form.Select>
						<option>7 Day Volume</option>
						<option>Default select</option>
					</Form.Select>
					<Form.Select>
						<option>Owners</option>
						<option>Default select</option>
					</Form.Select>
					<div className='searchbar-rightside__text'>
						7 Day Avg Price
					</div>
					<div className='searchbar-rightside__text'>Total Sale</div>
					<div className='searchbar-rightside__text'>7 Day Sale</div>
					<div className='searchbar-rightside__text me-4'>
						Est Market Cap
					</div>
				</div>
			)}
		</div>
	);
};

export default SearchBar;

import React from 'react';
import BreadCrumb from '../shared/Common/BreadCrumb';
import WrapperDiv from '../shared/Common/WrapperDiv';
import CollectionsCard from '../shared/Common/CollectionsCard';
import CollectionTitleBar from './CollectionTitleBar';

const CollectionCardsListing = () => {
	return (
		<>
			<BreadCrumb />
			<CollectionTitleBar />
			<WrapperDiv title='Rated NFTs'>
				<div className='collection-wrapper'>
					<div className='collection-cards__wrapper'>
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
						<CollectionsCard />
					</div>
				</div>
			</WrapperDiv>
		</>
	);
};

export default CollectionCardsListing;

import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CollectionsCard = () => {
	return (
		<Link className='common-link' to='/rated-nft/collection'>
			<Card className='common__card collection-card'>
				<img
					className='collection-card__img'
					src='/images/sample1.jpg'
					alt=''
				/>
				<div className='collection-card__right'>
					<p>
						<span>Rank:</span> # 7804
					</p>
					<p>
						<span>Token Id:</span> 8839291
					</p>
					<p>
						<span>Last sale:</span> 66 ETH
					</p>
					<button className='common-btn collection-card__btn'>
						View on Opensea
					</button>
				</div>
			</Card>
		</Link>
	);
};

export default CollectionsCard;

import React from 'react';
import Form from 'react-bootstrap/Form';

const Input = ({ type, label, placeholder, icon, className, desc }) => {
	return (
		<Form.Group className={className}>
			{label && <Form.Label>{label}</Form.Label>}
			{desc && <p className='input__desc'>{desc}</p>}
			<Form.Control type={type} placeholder={placeholder} />
			{icon && (
				<img
					className='search__icon-img'
					src='/images/search.svg'
					alt='search'
				/>
			)}
		</Form.Group>
	);
};

export default Input;
